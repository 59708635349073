<template>
  <div>

    <div
      class="d-flex align-items-start filter-form"
    >
      <div>
        <b-form
          class="mb-2"
          inline
        >
          <b-form-input
            class="mb-2 mr-sm-2 mb-sm-0"
            v-model="filter.code"
            placeholder="Search by shortcode"
            style="width: 200px;"
          ></b-form-input>

          <b-button
            pill
            class="mt-lg-0 mt-md-2 mr-sm-2"
            variant="primary"
          >Search</b-button>
          <b-button
            pill
            class="mt-lg-0 mt-md-2 mr-sm-2"
            variant="primary"
            @click="reset"
          >Reset</b-button>

        </b-form>
      </div>

      <b-button
        pill
        class="ml-auto"
        variant="outline-success"
        @click="$refs.addModal.show()"
      >
        <b-icon
          icon="plus-circle"
          class="mr-1"
        ></b-icon>
        Create Link
      </b-button>

    </div>
    <hr />

    <div
      class="text-center"
      v-if="!urlList.length && !loading"
    >
      <img
        src="@/assets/images/no-data.png"
        width="150"
      />
      <h5 class="mt-2">No URLs Found</h5>
      <b-button
        pill
        class="mt-2 mb-4"
        variant="outline-success"
        @click="$refs.addModal.show()"
      >
        <b-icon
          icon="plus-circle"
          class="mr-1"
        ></b-icon>
        Create Short Link
      </b-button>
    </div>

    <b-table
      v-else
      :items="urlList"
      :fields="fields"
      id="urlList"
      :busy="loading"
      head-variant="light"
      responsive
      hover
    >

      <template #table-busy>
        <div class="text-center text-primary mt-5 mb-1">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <template #cell(original_url)="data">
        <a
          :href="data.item.original_url"
          target="_blank"
        >
          Go to url
        </a>
      </template>

      <template #cell(add_time)="data">
        {{ data.item.add_time | dateFormat }}
      </template>

      <template #cell(analytics)="data">
        <b-button
          size="sm"
          variant="light"
          @click="showStats(data.item.shortcode)"
        >
          <b-icon icon="graph-up"></b-icon>
        </b-button>
        <b-button
          class="ml-2"
          size="sm"
          variant="light"
          @click="editLink(data.item)"
        >
          <b-icon icon="pencil-square"></b-icon>
        </b-button>
      </template>

    </b-table>

    <b-pagination
      v-if="total > 50 && !loading"
      v-model="currentPage"
      :total-rows="total"
      :per-page="perPage"
      align="center"
      pills
      @input="handlePageClick"
    ></b-pagination>

    <b-modal
      :title="`${isEdit ? 'Update Link' : 'Create New Link'}`"
      ref="addModal"
      hide-footer
      centered
      @hidden="resetForm()"
    >
      <validation-observer
        ref="createurlrules"
        v-if="!isUrlCreated"
      >
        <b-form>

          <b-form-group label="Campaign Id">
            <validation-provider
              #default="{ errors }"
              name="Campaign Id"
              rules="required"
            >
              <b-form-input
                v-model="urlData.campaignId"
                placeholder="Enter Campaign Id"
                :state="errors.length > 0 ? false : null"
                readonly
              ></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Influencer Id">
            <validation-provider
              #default="{ errors }"
              name="Influencer Id"
              rules="required"
            >
              <b-form-input
                v-model="urlData.influencerId"
                placeholder="Enter Influencer Id"
                :state="errors.length > 0 ? false : null"
              ></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Original Url">
            <validation-provider
              #default="{ errors }"
              name="Original Url"
              rules="required"
            >
              <b-form-input
                v-model="urlData.originalUrl"
                placeholder="Enter Original Url"
                :state="errors.length > 0 ? false : null"
              ></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Android Url">
            <b-form-input
              v-model="urlData.androdUrl"
              placeholder="Enter Android Url"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="iOS Url">
            <b-form-input
              v-model="urlData.iosUrl"
              placeholder="Enter iOS Url"
            ></b-form-input>
          </b-form-group>

          <div class="d-flex justify-content-end">
            <b-spinner
              variant="primary"
              small
              v-if="createLinkLoading"
            ></b-spinner>
            <b-button
              pill
              type="submit"
              variant="primary"
              @click.prevent="updateUrl"
              v-if="!createLinkLoading && isEdit"
            >Update</b-button>
            <b-button
              pill
              type="submit"
              variant="primary"
              @click.prevent="generateUrl"
              v-else-if="!createLinkLoading && !isEdit"
            >Create</b-button>
          </div>

        </b-form>
      </validation-observer>

      <b-form
        class="mb-2"
        v-else
      >
        <b-form-group label="Short Url">

          <b-input-group>
            <b-form-input
              v-model="shorturl"
              readonly
              class="shorturl"
            ></b-form-input>
            <b-input-group-append>
              <b-button
                variant="primary"
                @click="copyURL()"
                ref="shorturl"
              >Copy</b-button>
            </b-input-group-append>
          </b-input-group>

        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
      title="Link Analytics"
      size="xl"
      ref="statsModal"
      hide-footer
      centered
    >
      <div
        class="text-center"
        v-if="statsLoading"
      >
        <b-spinner
          style="margin: 200px 0; width: 3rem; height: 3rem;"
          variant="primary"
        ></b-spinner>
      </div>
      <b-row v-else>
        <b-col cols="4">
          <b-card>
            <p class="mb-0"><strong>Top Countries</strong></p>
            <canvas
              id="barChart"
              style="width:100%; height: 125px;"
            ></canvas>
          </b-card>
          <b-card class="mt-4">
            <p class="mb-0"><strong>Traffic Source</strong></p>
            <canvas
              id="pieChart"
              style="width:100%; height: 230px;"
            ></canvas>
          </b-card>
        </b-col>
        <b-col cols="8">
          <div class="d-flex mb-4 justify-content-between">
            <b-card class="infoCard">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0"><strong>Total Clicks</strong></p>
                  <h3 class="mb-0">{{ analytics.total_clicks }}</h3>
                </div>
                <div class="inforIcon ml-auto d-flex align-items-center justify-content-center">
                  <b-icon
                    icon="globe"
                    font-scale="1.5"
                    variant="primary"
                  ></b-icon>
                </div>
              </div>
            </b-card>
            <b-card class="infoCard">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0"><strong>Unique Clicks</strong></p>
                  <h3 class="mb-0">{{ analytics.unique_clicks }}</h3>
                </div>
                <div class="inforIcon ml-auto d-flex align-items-center justify-content-center">
                  <b-icon
                    icon="link-45deg"
                    font-scale="1.5"
                    variant="primary"
                  ></b-icon>
                </div>
              </div>
            </b-card>
            <b-card class="infoCard">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0"><strong>Top City</strong></p>
                  <h3 class="mb-0">{{ analytics.top_cities ? analytics.top_cities[0].city : 'NA' }}</h3>
                </div>
                <div class="inforIcon ml-auto d-flex align-items-center justify-content-center">
                  <b-icon
                    icon="geo-alt"
                    font-scale="1.5"
                    variant="primary"
                  ></b-icon>
                </div>
              </div>
            </b-card>
          </div>
          <b-card>
            <p><strong>Daily Visits</strong></p>
            <canvas
              id="lineChart"
              style="width:100%; height: 300px;"
            ></canvas>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>

  </div>
</template>

<script>
import Chart from 'chart.js'
import { urlList, createUrl, getStats, updateUrl } from '@/api/shorturl'

export default {
  data () {
    return {
      perPage: 20,
      currentPage: 1,
      total: 0,
      adminuser: window.localStorage.getItem('user-name'),
      loading: false,
      createLinkLoading: false,
      addModal: false,
      statsModal: false,
      statsLoading: false,
      isUrlCreated: false,
      isEdit: false,
      shorturl: '',
      filter: {
        campaignId: this.$route.params.campaignId,
        page: 1,
        size: 20
      },
      urlData: {
        campaignId: this.$route.params.campaignId
      },
      urlList: [],
      fields: [
        { key: 'index', label: '#' },
        // { key: 'campaign_name', label: 'Campaign Name' },
        // { key: 'influencer_name', label: 'Influencer Name' },
        // 'shortcode',
        'shortlink',
        { key: 'original_url', label: 'Original Url' },
        { key: 'add_time', label: 'Created At' },
        { key: 'analytics', label: 'Analytics' }
      ],
      analytics: [],
      lineChart: null,
      pieChart: null,
      barChart: null
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    async initChart () {
      const monthNames = ['Jan', 'Feb', 'May', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

      const past7Days = [...Array(7).keys()].map(index => {
        const date = new Date()
        date.setDate(date.getDate() - index)
        return date
      }).reverse()

      const weekData = {
        labels: [],
        clicks: []
      }

      past7Days.map((item, index) => {
        const date = new Date(item)
        const formattedDate = `${date.getDate()} ${monthNames[date.getMonth()]}`
        weekData.labels.push(formattedDate)

        const trackData = this.analytics.week.filter(item => {
          if (Number(item.trackDate) === Number(date.getDate())) { return item }
        })

        if (trackData.length) {
          weekData.clicks.push(trackData[0].cliks)
        } else {
          weekData.clicks.push(0)
        }
      })

      this.lineChart = new Chart('lineChart', {
        type: 'line',
        data: {
          labels: weekData.labels,
          datasets: [{
            data: weekData.clicks,
            label: 'Clicks',
            fill: 'start',
            fillColor: '#f54153',
            borderColor: '#4c62e3',
            pointBackgroundColor: '#4c62e3',
            backgroundColor: '#dadfff',
            pointRadius: 3,
            lineTension: 0,
            borderWidth: 2
          }]
        },
        options: {
          legend: {
            display: false
          },
          bezierCurve: false,
          scales: {
            xAxes: [{
              gridLines: {
                display: false
              }
            }],
            yAxes: [{
              gridLines: {
                display: true
              }
            }]
          }
        }
      })
      // this.lineChart.data.labels = weeklabels
      // this.lineChart.data.datasets[0].data = weekdata
      this.lineChart.update()

      const pieChartlabels = this.analytics.referer.map(item => {
        return item.source
      })

      // pieChart
      const pieChartdata = this.analytics.referer.map(item => {
        return item.visits
      })

      this.pieChart = new Chart('pieChart', {
        type: 'doughnut',
        data: {
          labels: pieChartlabels,
          datasets: [{
            data: pieChartdata,
            backgroundColor: ['#4C62E3', '#D255CA', '#FF5E9C', '#FF8C71', '#FFC45A', '#F9F871']
          }]
        }
      })
      this.pieChart.update()

      const barChartlabels = this.analytics.top_countries.map(item => {
        return item.country
      })

      // barChart
      const barChartdata = this.analytics.top_countries.map(item => {
        return item.visits
      })

      this.barChart = new Chart('barChart', {
        type: 'horizontalBar',
        data: {
          labels: barChartlabels,
          datasets: [{
            data: barChartdata,
            backgroundColor: ['#4C62E3', '#D255CA', '#FF5E9C', '#FF8C71', '#FFC45A', '#F9F871']
          }]
        },
        options: {
          legend: {
            display: false
          },
          bezierCurve: false,
          scales: {
            xAxes: [{
              gridLines: {
                display: false
              }
            }],
            yAxes: [{
              gridLines: {
                display: false
              }
            }]
          }
        }
      })
      this.barChart.update()
    },

    async showStats (shortcode) {
      this.$refs.statsModal.show()
      this.statsLoading = true
      const response = await getStats(shortcode)
      this.analytics = response.data
      this.statsLoading = false
      setTimeout(() => {
        this.initChart()
      }, 100)
    },
    reset () {
      this.filter = {
        campaignId: this.$route.params.campaignId,
        page: 1,
        size: 20
      }
      this.currentPage = 1
      this.getList()
    },
    async getList () {
      try {
        this.loading = true
        const response = await urlList(this.filter)
        this.urlList = response.data
        this.loading = false
      } catch (error) {
        this.$bvToast.toast(error.msg, {
          title: 'Server error!',
          variant: 'danger',
          solid: true
        })
      }
    },

    async updateUrl () {
      this.urlData.createdBy = this.adminuser
      this.$refs.createurlrules.validate().then(async success => {
        if (success) {
          this.createLinkLoading = true
          const response = await updateUrl(this.urlData)
          if (response.success) {
            this.shorturl = response.data.shorturl
            this.isUrlCreated = true
            this.createLinkLoading = false
            await this.getList()
          } else {
            this.$bvToast.toast(response.message, {
              title: 'Error!',
              variant: 'danger',
              solid: true
            })
          }
        }
      })
    },

    async generateUrl () {
      this.urlData.createdBy = this.adminuser
      this.$refs.createurlrules.validate().then(async success => {
        if (success) {
          this.createLinkLoading = true
          const response = await createUrl(this.urlData)
          if (response.success) {
            this.shorturl = response.data.shorturl
            this.isUrlCreated = true
            this.createLinkLoading = false
            await this.getList()
            this.resetForm()
          } else {
            this.$bvToast.toast(response.message, {
              title: 'Error!',
              variant: 'danger',
              solid: true
            })
          }
        }
      })
    },

    async editLink (item) {
      this.isEdit = true
      this.urlData = {
        shortcode: item.shortcode,
        campaignId: this.$route.params.campaignId,
        influencerId: item.influencer_id,
        originalUrl: item.original_url,
        androdUrl: item.android_url,
        iosUrl: item.ios_url
      }
      this.$refs.addModal.show()
    },

    resetForm () {
      this.isEdit = false
      this.urlData = {
        campaignId: this.$route.params.campaignId
      }
      this.shorturl = ''
      this.isUrlCreated = false
    },

    copyURL () {
      var element = document.querySelector('.shorturl')
      element.select()
      document.execCommand('copy')

      this.$bvToast.toast('Link copied to clipboard', {
        title: 'Copied',
        variant: 'success',
        autoHideDelay: 2000,
        solid: true
      })
    }
  }
}
</script>

<style scoped>
  p {
    font-size: 14px;
    color: #74788d;
  }
  .infoCard {
    width: 31%;
  }
  .inforIcon {
    height: 40px;
    width: 40px;
    /* background: #4c62e3; */
    border: 1px solid #4c62e3;
    border-radius: 50%;
  }
</style>
