import request from './index'

export const urlList = (data) => {
  return request({
    method: 'GET',
    url: '/shorturl/list',
    params: data
  })
}

export const createUrl = (data) => {
  return request({
    method: 'POST',
    url: '/shorturl/create',
    data: data
  })
}

export const updateUrl = (data) => {
  return request({
    method: 'POST',
    url: '/shorturl/update',
    data: data
  })
}

export const getStats = (shortcode) => {
  return request({
    method: 'GET',
    url: `/shorturl/stats/${shortcode}`
  })
}
